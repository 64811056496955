var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Youtube = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M21.974 10.465q-.01-.604-.095-1.522a14 14 0 0 0-.24-1.646q-.178-.814-.774-1.371a2.38 2.38 0 0 0-1.389-.647Q17.001 5 11.993 5t-7.484.279q-.792.09-1.383.647-.59.557-.77 1.371a12 12 0 0 0-.25 1.646 22 22 0 0 0-.095 1.522q-.01.602-.011 1.673 0 1.07.011 1.673.012.602.095 1.522.084.92.24 1.645.178.814.775 1.372.597.557 1.388.646 2.476.28 7.484.28 5.007 0 7.483-.28.792-.089 1.383-.646.59-.558.77-1.372a12 12 0 0 0 .25-1.645 22 22 0 0 0 .095-1.522q.012-.603.011-1.673 0-1.071-.01-1.673m-6.033 2.275-5.71 3.569a.64.64 0 0 1-.38.111.8.8 0 0 1-.345-.09.66.66 0 0 1-.368-.624V8.57q0-.423.368-.624.379-.201.725.022l5.71 3.569q.334.19.334.602 0 .413-.334.602" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Youtube;
